.section-box {
    max-width: 50vw;
    margin: auto;
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #0006;
    width: 100%;
    flex-grow: 1;
    color: black;
}

.section-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    width: 100%;
    color: #0d2b58;
}

.section-title h2 {
    font-size: 2rem;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0rem;
}

.section-description {
    padding-top: 1.5rem;
    line-height: 1.6;
    font-size: 1.6rem;
}
