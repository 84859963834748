.location-modal {
    &__card {
        width: 80%;
        max-width: 60rem;
        height: auto;
        padding-bottom: 4rem;
        border-radius: 1.2rem;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        overflow: hidden;
    }

    &__img {
        border-radius: 1.2rem 1.2rem 0 0;
        width: 100%;
    }

    &__content {
        width: 100%;
        padding: 0 2.4rem;
    }

    &__main {
        margin-bottom: 4rem;
        text-align: center;
        color: $text-dark;

        .heading-secondary {
            line-height: 2.4rem;
        }

        div {
            line-height: 2rem;
        }
    }

    &__buttons-wrapper {
        &--primary {
            margin: 0 3.2rem;
            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 600px) {
                margin: 0 0.5rem;
            }
            button {
                @media only screen and (max-width: 600px) {
                    width: 100%;
                    justify-content: center;
                    text-align: center;
                    font-size: 1.6rem;
                    padding: 1.3rem 0.5rem;
                    margin: 0 0.5rem;
                }
            }
        }
    }

    &__chevron {
        height: 3.2rem;
        position: absolute;
        top: 12.4rem;
        cursor: pointer;

        &--left {
            left: 1.2rem;
        }

        &--right {
            right: 1.2rem;
        }
    }

    &__indicator-dots {
        position: absolute;
        bottom: 5.5rem;
        left: 50%;
        transform: translateX(-50%);
        @media only screen and (max-width: 600px) {
            bottom: 10rem;
        }
    }
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.75);
    }
    &__body {
        margin-top: 1.5rem;
    }
}
