.toggle {
  display: flex;
  align-items: stretch;
  border: 2px solid $gray-3;
  border-radius: $border-radius;
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 700;
  overflow: hidden;

  &-button {
    padding: 1.3rem 1.4rem;

    &--selected {
      background-color: $primary-bright-blue;
      color: $white;
    }
  }

  &-separator {
    width: 2px;
    background-color: $gray-3;
  }
}
