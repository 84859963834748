.search{
 &-searchbar {
        height: 7rem;
        width: 100%;
        border-radius: $border-radius;
        background-color: #fff;
        line-height: 2.7rem;
        outline: solid 2px $gray-1;
        position: relative;
        display: flex;

        &-focus {
            border-radius: 1.2rem 1.2rem 1.2rem 0;
        }

        &__icon {
            display: none !important;
            transition: all 0.5s ease;
        }
        &__close:hover {
            opacity: 0.7;
        }
        &-button {
            padding: 0.8rem;
        }

        &-separator {
            background-color: $gray-2;
            width: 1px;
            height: 4rem;
            z-index: 1;
            display: inline-block;
        }

        &-text {
            order: 1;
            color: $text-gray-cold;
            font-weight: 700;
            font-size: 2rem;
            transition: all 0.5s ease;
        }

        &-inputs {
            flex: 1;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;

            & > * :not(.input-separator) {
                margin-left: 6%;
            }
            &__right,
            &__left {
                display: flex;
                width: 100%;
                button {
                    order: 3;
                    background: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    outline: none;
                    cursor: pointer;
                    scale: 2;
                    margin-right: 1rem;
                }
            }
        }

        &-input {
            display: inline-block;
            order: 2;
            border: none;
            height: 100%;
            width: 100%;
            font-family: inherit;
            font-size: 2rem;

            &:focus {
                outline: none;
                color: $text-dark;
            }

            &::placeholder {
                color: $text-gray-cold;
            }
        }

        &-input:focus ~ &-text {
            color: $text-dark;
        }

        &-input:focus ~ &__icon {
            color: $text-dark;
        }
    }
 


    @media only screen and (width <=37.5em) {
        &-searchbar {
            line-height: 2.2rem;
            height: fit-content;
            display: flex;
            flex-direction: row;
            flex-grow: 1;

            // padding: 1.2rem 0;
            outline: none;
            background-color: transparent;

            &__icon {
                color: $text-gray-cold;
                font-size: 1.7rem;
                display: flex !important;
                margin-right: 0.8rem;
            }

            &-focus {
                border-radius: 1.2rem;
            }

            &-separator {
                display: none;
            }

            &-text {
                display: none;
            }
            
            &-button {
                padding: 0.8rem;
                margin-left: 0;
                padding-right: 10px;

                align-items: center;
                background-color: #f1f8ff;;
                border: none;
                cursor: pointer;
            }

            &-inputs {
                align-content: center;
                align-self: center;
                flex-grow: 1;
                
                align-items: center;
                height: 100%;
                max-width: 75em;
                min-width: 17em;
                width: 100%;
                flex-wrap: wrap;

                & > * :not(.input-separator) {
                    margin-left: 0;
                }

                &__left,
                &__right {
                    align-self: center;
                    padding-left: 2rem;
                    padding-right: 0;
                    height: 4.8rem;
                    outline: solid 1px $gray-1;
                    border-radius: $border-radius;
                    display: flex;
                    align-items: center;
                    transition: outline 0.1s;
                    overflow: hidden;
                    background-color: #fff;
                }

                &__left {
                    margin-bottom: 0.8rem;
                }

                &__left:focus-within {
                    outline: 2px solid $text-dark;
                    outline-offset: -2px;
                }

                &__right {
                    transform: translateY(1rem);
                    display: none;
                }

                &__visible {
                    transform: translateY(0);
                    display: flex;
                }

                &__right:focus-within {
                    outline: 2px solid $text-dark;
                    outline-offset: -2px;
                }
            }

            &-input {
                font-size: 1.6rem;
            }

            &-input:focus ~ &-text {
                color: $text-dark;
            }
        }
    }
 }
.landing {
    &-searchbar {
        height: 7rem;
        width: 100%;
        border-radius: $border-radius;
        background-color: #fff;
        line-height: 2.7rem;
        outline: solid 2px $gray-1;
        position: relative;
        display: flex;
    
        &-focus {
            border-radius: 1.2rem 1.2rem 1.2rem 0;
        }
    
        &__icon {
            display: none !important;
            transition: all 0.5s ease;
        }
        &__close:hover {
            opacity: 0.7;
        }
        &-button {
            padding: 0.8rem;
        }
    
        &-separator {
            background-color: $gray-2;
            width: 1px;
            height: 4rem;
            z-index: 1;
            display: inline-block;
        }
    
        &-text {
            order: 1;
            color: $text-gray-cold;
            font-weight: 700;
            font-size: 2rem;
            transition: all 0.5s ease;
        }
    
        &-inputs {
            flex: 1;
            display: flex;
            align-items: center;
            height: 100%;
            width: 100%;
    
            & > * :not(.input-separator) {
                margin-left: 6%;
            }
            &__right,
            &__left {
                display: flex;
                width: 100%;
                button {
                    order: 3;
                    background: transparent;
                    border: none;
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    outline: none;
                    cursor: pointer;
                    scale: 2;
                    margin-right: 1rem;
                }
            }
        }
    
        &-input {
            display: inline-block;
            order: 2;
            border: none;
            height: 100%;
            width: 100%;
            font-family: inherit;
            font-size: 2rem;
    
            &:focus {
                outline: none;
                color: $text-dark;
            }
    
            &::placeholder {
                color: $text-gray-cold;
            }
        }
    
        &-input:focus ~ &-text {
            color: $text-dark;
        }
    
        &-input:focus ~ &__icon {
            color: $text-dark;
        }
    }
    &-searchbar {
        line-height: 2.2rem;
        height: fit-content;
        display: flex;
        flex-direction: row;
        flex-grow: 1;

        // padding: 1.2rem 0;
        outline: none;
        background-color: transparent;

        &__icon {
            color: $text-gray-cold;
            font-size: 1.7rem;
            display: flex !important;
            margin-right: 0.8rem;
        }

        &-focus {
            border-radius: 1.2rem;
        }

        &-separator {
            display: none;
        }

        &-text {
            display: none;
        }
        
        &-button {
            padding: 0.8rem;
            margin-left: 0;
            padding-right: 10px;
            display: flex;
            align-items: center;
            background-color: #f1f8ff;;
            border: none;
            cursor: pointer;
        }

        &-inputs {
            align-content: center;
            align-self: center;
            flex-grow: 1;
            display: flex;
            align-items: center;
            height: 100%;
            max-width: 75em;
            min-width: 17em;
            width: 100%;
            flex-wrap: wrap;

            & > * :not(.input-separator) {
                margin-left: 0;
            }

            &__left,
            &__right {
                align-self: center;
                padding-left: 2rem;
                padding-right: 0;
                height: 4.8rem;
                outline: solid 1px $gray-1;
                border-radius: $border-radius;
                display: flex;
                align-items: center;
                transition: outline 0.1s;
                overflow: hidden;
                background-color: #fff;
            }

            &__left {
                margin-bottom: 0.8rem;
            }

            &__left:focus-within {
                outline: 2px solid $text-dark;
                outline-offset: -2px;
            }

            &__right {
                transform: translateY(1rem);
                display: none;
            }

            &__visible {
                transform: translateY(0);
                display: flex;
            }

            &__right:focus-within {
                outline: 2px solid $text-dark;
                outline-offset: -2px;
            }
        }

        &-input {
            font-size: 1.6rem;
        }

        &-input:focus ~ &-text {
            color: $text-dark;
        }
    }
    @media only screen and (max-width: 600px) {
        .search-searchbar {
            display: none;
        }
    }
}
