.map-zoom {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 2px solid $gray-3;
  border-radius: $border-radius;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.1rem;
    min-height: 4.6rem;
  }

  &__divider {
    height: 2px;
    background-color: $gray-3;
  }
}
