.footer {
    background-color: #3A3B3F;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    padding: 10rem 05vw;
    min-height: 15vh;
    flex-shrink: 0;
    margin: auto;

    &__content {
        display: flex;
        width: fit-content;
        color: $white;
        width: 70rem;
        margin-left: 4rem;
    }
    &__column {
        flex: 1;
        width: fit-content;
        margin-right: 3rem;
    }

    &__header {
        font-weight: 700;
        font-size: 2rem;
        margin-bottom: 1.4rem;
    }

    &__link {
        color: $white;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 3.6rem;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;
        cursor: pointer;

        &-logo {
            margin-right: 0.5rem;
            font-size: inherit;
            display: flex !important;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    &__guide {
        background-color: none;
        width: 15rem;
    }

    @media only screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        &__content {
            margin: auto;
            width: fit-content;
        }
        &__logo {
            transform-origin: top left;
            transform: scale(0.8);
            margin-bottom: 2rem;
        }
    }

    @media only screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4.8rem 0;
        height: fit-content;

        &__logo {
            order: 1;
            transform: scale(1);
            margin: auto;
        }

        &__content {
            flex-direction: column;
            align-items: center;
            text-align: center;
            width: fit-content;
            color: $white;

            & > *:not(:last-child) {
                margin-right: 0;
            }
        }

        &__header {
            font-weight: 700;
            font-size: 1.6rem;
            margin-bottom: 0.8rem;
        }

        &__column {
            margin-bottom: 4rem;
            flex: 1;
            width: fit-content;
            margin-right: 0;
        }

        &__link {
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 3.6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;

            &-logo {
                margin-right: 0.5rem;
                font-size: inherit;
            }
        }

        &__guide {
            display: none;
        }
    }
}
