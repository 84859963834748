.search-page__pagination {
    margin: 4rem 6rem;
}

.MuiPagination-root ul li {
    margin: 0.6rem;
    font-size: 2rem;
}
.MuiPagination-root ul li button {
    font-size: 1.8rem;
}
