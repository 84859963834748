.search-page {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;

    &__main {
        display: flex;
        width: 100%;
        height: 100%;
    }

    &__results {
        display: flex;
        height: auto;
        flex-direction: column;
        width: 65%;
    }

    &__map {
        display: flex;
        position: sticky;
        top: 0;
        height: 100vh;
        width: 35%;
    }
    &__filters {
        display: flex;
        flex-wrap: wrap;
        margin: 1rem;
        button {
            margin: 1rem;
        }
        .filter-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            font-size: 1.8rem;
            color: #00233d;
            font-weight: 700;
            line-height: 2rem;
            letter-spacing: -0.08px;
            text-align: center;
            outline: 2px solid #00233d;
            box-shadow: #00233d 0 0 0 1px inset;
            outline-offset: -1px;
            border-radius: 12px;
            padding: 0rem 2rem;
            height: 5.2rem;
            margin: 1rem;
            cursor: pointer;
        }
    }
    &__loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        text-align: justify;
        margin: 0 20%;
    }
    &__warning {
        display: flex;
        justify-content: center;
        align-items: center;
        height: max-content;
    }
}
.p1search {
    font-size: larger;
    font-weight: bolder;
}
