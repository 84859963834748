.search {
    &-suggestions {
        position: absolute;
        // width: 100%;
        width: 40%;
        left: 46%;
        // padding-right: 13.1rem;
        z-index: 10;
        @media only screen and (max-width: 1024px) {
            left: 40%;
        }
    }

    &-results {
        margin-left: -2px;
        background-color: $white;
        height: max-content;
        // width: 49.8%;
        width: 100%;
        border: solid $gray-1 2px;
        border-top: none;
        border-radius: 0 0 1.2rem 1.2rem;
        display: flex;
        flex-direction: column;
        cursor: pointer;

        &::after {
            content: '';
            z-index: -1;
            box-shadow: 0 0 4rem -1rem rgba(#000, 0.15);
        }

        &__location {
            // margin-left: 49.7%;
        }
        &__currentlocation {
            // margin-left: 49.7%;
            border-radius: 0;
        }
    }

    &-item {
        padding: 1.8rem 0;
        font-weight: 400;
        color: $text-dark;
        font-size: 1.8rem;

        &__icon {
            font-size: 2.2rem;
            display: flex !important;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            background-color: rgba($gray-1, 0.3);
        }

        & > p {
            margin-left: 6%;
        }

        &:not(:last-child) {
            // border-bottom: 1px solid $gray-2;
        }

        &__location {
            color: $primary-bright-blue;
            padding-left: 6%;
            display: flex;
            align-items: center;

            p {
                margin-left: 0.8rem;
                width: 100%;
            }
        }
    }
}

@media only screen and (width <= 37.5em) {
    .search {
        &-suggestions {
            padding-right: 0;
            margin-top: 1.7rem;
            top: 55%;
            left: 0%;
            width: 100%;
        }

        &-results {
            margin-left: 0;
            width: 100%;
            border: none;
            border-radius: 0;
            border-bottom: solid $gray-1 1px;

            &::after {
                content: '';
                z-index: -1;
                box-shadow: 0 0 4rem -1rem rgba(#000, 0.15);
            }

            &__location {
                margin-left: 0;
            }
        }

        &-item {
            padding: 0;
            font-size: 1.6rem;
            line-height: 2.2rem;

            &__icon {
                font-size: 2rem;
                margin: 1.5rem 0 1.1rem;
            }

            & > p {
                margin: 1.5rem 0 1.1rem;
                margin-left: 1.2rem;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $gray-2;
            }

            &__location {
                padding-left: 1.2rem;

                p {
                    margin-left: 0.8rem;
                    width: 100%;
                }
            }
        }
    }
}
