.table {
    border-color: $white;
    padding: 0 3.2rem;
    outline: 2px solid $gray-3;
    outline-offset: -2px;
    border-radius: $border-radius;
    font-size: 2rem;
    font-weight: 700;
    color: $text-dark;
    @media only screen and (max-width: 767px) {
        padding: 0 1rem;
    }

    &__icon {
        font-size: 2.7rem;
    }

    &__blue {
        color: $primary-bright-blue;
        span {
            cursor: pointer;
        }
        a {
            text-decoration: none;
            color: $primary-bright-blue;
        }
    }

    & > * {
        padding: 3.2rem 0;
    }

    & > *:not(:last-child) {
        border-bottom: 2px solid rgba($gray-3, 0.9);
    }

    &__section {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__report {
        display: none;
    }

    &__content {
        margin-right: auto;
    }

    &__address {
        margin-top: 1.2rem;
        line-height: 2.2rem;
        font-size: 1.6rem;
        font-weight: 600;
    }

    &__distance {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1.9rem;
        color: rgba($text-dark, 0.5);
    }

    &__hours {
        display: flex;
        flex-direction: column;
        width: 100%;
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        span {
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
        }

        &--table {
            margin-top: 1.2rem;
            font-size: 1.6rem;
            font-weight: 600;
            width: 100%;

            &-left {
                height: 4rem;
                width: 100px;
            }

            &-right {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                min-height: 4rem;
                font-weight: 400;
            }
        }
        .timeColor {
            color: #007d1d;
            &__text {
                vertical-align: top;
            }
        }
    }

    @media only screen and (width <= 37.5em) {
        outline: none;
    }
}
