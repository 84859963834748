.hours-modal {
    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(0, 0, 0, 0.75);
    }
    &__card {
        width: 80%;
        max-width: 60rem;
        height: auto;
        padding: 4rem 2rem;
        border-radius: 1.2rem;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        overflow: hidden;
        @media screen and (max-width: 600px) {
            overflow-y: scroll;
            height: 80%;
        }
    }
    &__mon,
    &__tue,
    &__wed,
    &__thu,
    &__fri,
    &__sat,
    &__sun {
        display: flex;
        align-items: center;
        margin: 0 0;
        border: 1px solid rgba(0, 0, 0, 0.23);
        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
    &__text1 {
        display: flex;
        justify-content: flex-start;
        width: 12rem;
        margin-left: 2rem;
        align-items: center;
        height: 45px;
    }
    &__btnWrapper {
        width: 90%;
        margin: 1rem 0;
        display: flex;
        justify-content: space-between;
    }
}
