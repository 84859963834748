.div1 {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    button {
        margin-left: 2rem;
        width: fit-content;
        margin-top: 0;
    }
}
.div2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 100%;
    img {
        transform-origin: center;
        transform: scale(0.4);
    }
    p {
        width: max-content;
        text-align: center;
        @media only screen and (600px <= width <= 768px) {
            scale: 0.8;
        }
    }
}
