.dashboard {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem 4rem;
    padding: 3rem;
    border: 2px solid #caccd3;
    border-radius: 12px;
    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    &__addresource {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        // margin-left: 2rem;
        & > *:not(:first-child) {
            gap: 4rem;
            @media only screen and (width <= 37.5em) {
                gap: 0.5rem;
            }
        }
    }
    &__addname,
    &__addcontact,
    &__addaddress,
    &__addabout {
        display: flex;
        @media only screen and (width <= 37.5em) {
            flex-direction: column;
            margin-top: 2rem;
            // & > *:not(:first-child) {
            //     margin-top: 2rem;
            // }
        }
    }
    &__addabout {
        button {
            width: 100%;
            margin-top: 1.45rem;
            color: rgba(0, 0, 0, 0.6);
            font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
            font-weight: 400;
            font-size: 1.5rem;
            line-height: 1.8em;
            letter-spacing: 0.00938em;
            box-shadow: #00233d 0 0 0 1.5px inset;
            @media only screen and (width <= 37.5em) {
                justify-content: flex-start;
                padding: 1.5rem;
            }
        }
    }
    &__button-wrapper {
        display: flex;
        justify-content: flex-end;
        button {
            margin-left: 3rem;
        }
    }
}

.dashboardtext1 {
    margin: 1rem;
}
.dasboardtext2 {
    width: 7rem;
    margin-right: 2rem;
}
.heading-secondary {
    margin-bottom: 3rem;
}
