.search-page--responsive {
    &__map {
        height: 80vh;
    }

    &__results {
        min-height: 80vh;
        height: auto;
    }
    &__bar {
        display: flex;
        justify-content: space-between;
        margin: 0.7rem 2rem;
        button {
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #00233d;
            width: 8rem;
            margin: 0;
            background: #ffffff;
            border: 2px solid #caccd3;
        }
    }
    &__warning {
        display: flex;
        justify-content: center;
        align-items: center;
        height: max-content;
    }
    &__loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50vh;
        text-align: justify;
        margin: 0 10%;
    }
}
.heading-primary {
    margin: 1rem 1rem;
    @media only screen and (max-width: 767px) {
        font-size: 2.5rem;
        display: flex;
        align-self: flex-start;
        padding: 0 4%;
        margin-bottom: 0em;
    }
}
.p1search {
    font-size: larger;
    font-weight: bolder;
}
