.pills {
    display: flex;
    align-items: center;
    width: max-content;
    padding: .8rem 1.2rem;
    // font-weight: bold;
    font-size: 1.6rem;
    font-family: Atkinson Hyperlegible, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    border-radius: 4px;

    &--warning {
        background: #f7e1ce;
        color: #954700;
    }

    &--success {
        background: #def3e3;
        color: #007d1d;
    }

    &--error {
        background: #f0d8d8;
        color: #c80000;
    }

    &--general {
        background: #DFEFD8;
        color: #29481B;
    }

    // &--icon {
    //     margin-right: 1rem;
    //     font-size: 2.2rem;
    //     width: 100%;

    //     &-wrapper {
    //         display: flex;
    //         align-items: center;
    //         margin-right: 1rem;
    //     }
    // }

    &--no-background {
        background: none;
    }
}
