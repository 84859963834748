.ServiceCard {
    display: flex;
    width: 728px;
    padding: 32px;
    align-items: flex-start;
    align-content: flex-start;
    gap: 24px 109px;
    flex-wrap: wrap;
    border-radius: 8px;
    border: 1px solid var(--FRM-Neutral-neutral-100, #D1D1D3);
    background: var(--FRM-White, #FFF);
    box-shadow: 4px 8px 32px 0px rgba(0, 0, 0, 0.16);
    margin-bottom: 16px;

    &__textField {
        display: flex;
        width: 579px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        list-style-type: disc;

        color: var(--FRM-Neutral-neutral-900, #2C2D30);
        font-family: "Atkinson Hyperlegible";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 157.143% */
    }

    &__textField li {
        margin-left: 20px;
    }

    &__name {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
    }

    &__service {
        display: flex;
        width: 450px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;
        margin-right: 16px;
    }

    &__name, &__service {
        padding: 8px 12px;
        border-radius: 8px;
        border: 1px solid var(--FRM-Neutral-neutral-500, #696B72);

        color: var(--FRM-Neutral-neutral-700, #4B4C51);
        font-family: "Atkinson Hyperlegible";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }

    &__add {
        display: inline-flex;
        padding: 8px 24px;
        justify-content: center;
        align-items: center;
        gap: 8px;

        border-radius: 8px;
        border: 1px solid var(--FRM-Neutral-neutral-100, #D1D1D3);
        background: var(--FRM-White, #FFF);

        color: var(--FRM-Neutral-neutral-900, #2C2D30);

        /* Label Medium */
        font-family: "Atkinson Hyperlegible";
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px; /* 150% */
        letter-spacing: 0.32px;

        cursor: pointer;
    }

    &__add:hover{
        background: var(--FRM-Neutral-neutral-100, #D1D1D3);
    }

    &__servicesArea {
        display: flex;
        flex-direction: row;
    }

    &__listArea {
        margin-left: 2px;
        display: flex;
        align-items: center;
        color: var(--FRM-Neutral-neutral-900, #2C2D30);
        /* Body Medium */
        font-family: "Atkinson Hyperlegible";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
    }

    &__deleteDetail {
        margin-left: 8px;
        cursor: pointer;
    }

    &__delete {
        position: absolute;
        margin-left: 650px;
        margin-top: -15px;
        cursor: pointer;
    }
}


