.graphic-button {
  border: 2px solid #caccd3;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  padding: 1.8rem 1.6rem;
  cursor: pointer;

  @media only screen and (width <= 37.5em) {
    padding: 1.6rem;
  }

  &__graphic {
    height: 5.4rem;
    width: auto;
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 20rem;
    margin-left: 2.7rem;
    color: $text-dark;

    @media only screen and (width <= 37.5em) {
      width: 100%;
    }
  }

  &__title {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.7rem;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (width <= 37.5em) {
      font-size: 1.6rem;
      line-height: 2.2rem;
    }
  }

  &__paragraph {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 1.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
