.timePickers {
    &__time {
        width: 25rem;
        border-left: 1px solid rgba(0, 0, 0, 0.23);
        border-right: 1px solid rgba(0, 0, 0, 0.23);
        @media screen and (max-width: 600px) {
            border-top: 1px solid rgba(0, 0, 0, 0.23);
            border-bottom: 1px solid rgba(0, 0, 0, 0.23);
            border-right: none;
            border-left: none;
        }
    }
}
