.filter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-size: 1.8rem;
    color: $text-dark;
    font-weight: 700;
    line-height: 2rem;
    letter-spacing: -0.08px;
    text-align: center;
    outline: 2px solid $gray-3;
    outline-offset: -1px;
    border-radius: 3.3rem;
    padding: 1.7rem 2.2rem;

    @media only screen and (width <= 37.5em) {
        border-radius: $border-radius;
        padding: 1.3rem 1.3rem;
        font-size: 1.6rem;
    }

    &--selected {
        background-color: $off-white;
        outline: 2px solid $primary-bright-blue;
    }

    &__filtered {
        background-color: $primary-bright-blue;
        height: 1.6rem;
        width: 1.6rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5rem;
        padding: 1.2rem;

        & span {
            font-family: "SF Pro Display", sans-serif;
            font-size: 1rem;
            font-weight: 500;
            color: $white;
        }
    }
}
