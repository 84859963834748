.location-pin {
  position: relative;
  width: 4.6rem;
  height: min-content;

  &__yourIcon {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    font-size: 4.6rem;
    color: rgb(225, 6, 6);

    & svg circle {
      color: $white;
      transform-origin: initial;
      transform: scale(3) translateY(3.5rem);
    }
  }

  &__icon {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    font-size: 4.6rem;
    color: $primary-dark-blue;

    &--active {
      color: $primary-bright-blue;
    }

    & svg circle {
      transform-origin: initial;
      transform: scale(3) translateY(3.5rem);
    }
  }

  &__number {
    padding-top: 0.8rem;
    position: relative;
    color: $white;
    z-index: 1;
    font-size: 1.7rem;
    text-align: center;
  }

  &__you {
    padding-top: 1.3rem;
    position: relative;
    color: $white;
    z-index: 1;
    font-size: 1.4rem;
    text-align: center;
  }
}
