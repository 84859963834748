
.container {
  display: flex;
  flex-direction: column; // Stack main content and review-text vertically
  align-items: flex-start; // Align items to the start of the container
  margin-bottom: 20px; // Add space between different reviews
  margin-top:20px;

  .content-wrapper {
    display: flex;
    align-items: center; // Center items vertically in the row
    margin-bottom: 10px; // Space below the row and review text

    .circle {
      width: 60px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #E2EBFC;
      cursor: pointer;
      font-weight: bold;
      font-size: 15px;
      text-align: center;
      margin-right: 20px; // Space between circle and content
      flex-shrink: 0; // Prevent shrinking
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .box {
        width: 300px; // Adjusted width
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: left;
        border-radius: 8px;
        background-color: #FFFFFF;
        cursor: default;
        font-weight: bold;
        font-size: 15px;
        white-space: nowrap; // Prevent text wrapping
        overflow: hidden;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0px;
      }
    }
  }

  .rating-time-wrapper {
    display: flex; 
    align-items: center; // Align items vertically in the center
    gap: 0.5rem; // Add some space between the elements
  }

  .review-time {
    font-size: 14px;
    color: var(--FRM-Neutral-neutral-500, #696B72);
    text-align: left; // Align text to the left
  }

  .review-text {
    margin-top: 10px; // Space above the review text to separate it from the previous content
    font-size: 14px;
    color: #333;
    text-align: left; // Align text to the left
    max-width: 300px; // Optional: limit width to align with content
    line-height: 1.5; // Improve readability with line height
  }
  .button-group {
    display: flex;
    gap: 20px; // Space between buttons
    margin-top: 20px; // Space above the buttons
  }

  .action-button {
    width: 125px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #D1D1D3;
    background-color: #FFFFFF;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    white-space: nowrap; // Prevent text wrapping
    overflow: hidden; // Hide overflow
    margin-bottom: 20px; // Space below the buttons
    
  }
}
