@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap';
@import 'https://fonts.cdnfonts.com/css/sf-pro-display:wght@500';
@import 'https://fonts.googleapis.com/css2?family=Atkinson+Hyperlegible:wght@400;600;700&display=swap';
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
  box-sizing: border-box;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote::before, blockquote::after,
q::before, q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

html {
  font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #00233d;
}

body {
  font-size: 1.6rem;
}

.heading-primary {
  font-size: 4rem;
  font-weight: 700;
}

.heading-secondary {
  font-size: 2rem;
  font-weight: 700;
}

.u-flexbox {
  display: flex;
}

.u-relative {
  position: relative;
}

.button {
  font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 0.8rem;
  color: white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  font-size: 1.8rem;
  padding: 0.75rem 2rem;
  gap: 0.5rem;
  outline: 0;
}
.button:disabled {
  cursor: not-allowed;
}
.button:focus {
  outline: 2px solid #2067D2;
  outline-offset: 4px;
}
.button__icon {
  font-size: 1.5rem;
  color: #2067D2;
}
.button__primary {
  background-color: #2067D2;
}
.button__primary:hover {
  background-color: #174995;
}
.button__primary:disabled {
  background-color: #BAD0F1;
}
.button__secondary {
  color: #2C2D30;
  background-color: #fff;
  border: 1px solid #D1D1D3;
}
.button__secondaryGoogle, .button__secondaryMicrosoft, .button__secondaryEmail {
  background-color: #fff;
  color: #00233d;
  border: 2px solid #00233d;
  box-shadow: none;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 1.2rem 1rem;
}
.button__secondary:hover {
  background-color: #F0F0F1;
}
.button__secondary:focus {
  outline: 2px solid #2C2D30;
  outline-offset: 4px;
}
.button__secondary:disabled {
  color: #D1D1D3;
  background-color: #fff;
}
.button__secondaryMap {
  color: #2C2D30;
  background-color: #fff;
  border: 1px solid #D1D1D3;
  height: 30px;
}
.button__secondaryMap:hover {
  background-color: #F0F0F1;
}
.button__secondaryMap:focus {
  outline: 2px solid #2C2D30;
  outline-offset: 4px;
}
.button__secondaryMap:disabled {
  color: #D1D1D3;
  background-color: #fff;
}
.button__error {
  background-color: #DE1D1D;
}
.button__error:hover {
  background-color: #9E1515;
}
.button__error:disabled {
  background-color: #F5B9B9;
}
.button__tertiary {
  color: #2067D2;
  background-color: transparent;
  padding: 0.25rem 0.5rem;
}
.button__tertiary:hover {
  background-color: #E9F0FB;
}
.button__tertiary:disabled {
  color: #BAD0F1;
  background-color: transparent;
}
.button__link {
  color: #0052cc;
  background-color: transparent;
  padding: 1rem;
}
.button__small {
  line-height: 1.5rem;
  font-size: 1rem;
}
.button__large {
  line-height: 3rem;
  font-size: 2rem;
}
.button__browse-all-button {
  padding: 0.5rem 6rem;
  font-size: 1.5rem;
  color: #0d2b58;
  background-color: transparent;
  border: 2.5px solid #2067D2;
  border-radius: 8px;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: none;
  outline: none;
}
.button__browse-all-button:hover {
  background-color: #0d2b58;
  color: #ffffff;
}
@media only screen and (width <= 600px) {
  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.6rem;
    padding: 1.3rem 1rem;
    margin: 0 0.5rem;
  }
}

.indicator-dots > *:not(:last-child) {
  margin-right: 0.8rem;
}

.location-modal__card {
  width: 80%;
  max-width: 60rem;
  height: auto;
  padding-bottom: 4rem;
  border-radius: 1.2rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
}
.location-modal__img {
  border-radius: 1.2rem 1.2rem 0 0;
  width: 100%;
}
.location-modal__content {
  width: 100%;
  padding: 0 2.4rem;
}
.location-modal__main {
  margin-bottom: 4rem;
  text-align: center;
  color: #00233d;
}
.location-modal__main .heading-secondary {
  line-height: 2.4rem;
}
.location-modal__main div {
  line-height: 2rem;
}
.location-modal__buttons-wrapper--primary {
  margin: 0 3.2rem;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 600px) {
  .location-modal__buttons-wrapper--primary {
    margin: 0 0.5rem;
  }
}
@media only screen and (max-width: 600px) {
  .location-modal__buttons-wrapper--primary button {
    width: 100%;
    justify-content: center;
    text-align: center;
    font-size: 1.6rem;
    padding: 1.3rem 0.5rem;
    margin: 0 0.5rem;
  }
}
.location-modal__chevron {
  height: 3.2rem;
  position: absolute;
  top: 12.4rem;
  cursor: pointer;
}
.location-modal__chevron--left {
  left: 1.2rem;
}
.location-modal__chevron--right {
  right: 1.2rem;
}
.location-modal__indicator-dots {
  position: absolute;
  bottom: 5.5rem;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 600px) {
  .location-modal__indicator-dots {
    bottom: 10rem;
  }
}
.location-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}
.location-modal__body {
  margin-top: 1.5rem;
}

.welcome-modal__card {
  width: 80%;
  max-width: 60rem;
  height: auto;
  padding-bottom: 4rem;
  border-radius: 1.2rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
}
.welcome-modal__img {
  border-radius: 1.2rem 1.2rem 0 0;
  width: 100%;
}
.welcome-modal__content {
  width: 100%;
  padding: 0 2.4rem;
}
.welcome-modal__main {
  margin-bottom: 4rem;
  text-align: center;
  color: #00233d;
}
.welcome-modal__main .heading-secondary {
  line-height: 2.4rem;
}
.welcome-modal__main div {
  line-height: 2rem;
}
.welcome-modal__buttons-wrapper--secondary {
  margin: 0 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.welcome-modal__buttons-wrapper--secondary a {
  color: #0052cc;
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 700;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .welcome-modal__buttons-wrapper--secondary button {
    width: 7rem;
    justify-content: center;
  }
}
.welcome-modal__chevron {
  height: 3.2rem;
  position: absolute;
  top: 12.4rem;
  cursor: pointer;
}
.welcome-modal__chevron--left {
  left: 1.2rem;
}
.welcome-modal__chevron--right {
  right: 1.2rem;
}
.welcome-modal__indicator-dots {
  position: absolute;
  bottom: 5.5rem;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 600px) {
  .welcome-modal__indicator-dots {
    bottom: 10rem;
  }
}
.welcome-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}
.welcome-modal__body {
  margin-top: 1.5rem;
}

.pills {
  display: flex;
  align-items: center;
  width: max-content;
  padding: 0.8rem 1.2rem;
  font-size: 1.6rem;
  font-family: Atkinson Hyperlegible, "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 4px;
}
.pills--warning {
  background: #f7e1ce;
  color: #954700;
}
.pills--success {
  background: #def3e3;
  color: #007d1d;
}
.pills--error {
  background: #f0d8d8;
  color: #c80000;
}
.pills--general {
  background: #DFEFD8;
  color: #29481B;
}
.pills--no-background {
  background: none;
}

.section-box {
  max-width: 50vw;
  margin: auto;
  margin-top: 2rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  width: 100%;
  flex-grow: 1;
  color: black;
}

.section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  width: 100%;
  color: #0d2b58;
}

.section-title h2 {
  font-size: 2rem;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0rem;
}

.section-description {
  padding-top: 1.5rem;
  line-height: 1.6;
  font-size: 1.6rem;
}

.search-searchbar {
  height: 7rem;
  width: 100%;
  border-radius: 0.8rem;
  background-color: #fff;
  line-height: 2.7rem;
  outline: solid 2px #e0e2eb;
  position: relative;
  display: flex;
}
.search-searchbar-focus {
  border-radius: 1.2rem 1.2rem 1.2rem 0;
}
.search-searchbar__icon {
  display: none !important;
  transition: all 0.5s ease;
}
.search-searchbar__close:hover {
  opacity: 0.7;
}
.search-searchbar-button {
  padding: 0.8rem;
}
.search-searchbar-separator {
  background-color: #dfe1e6;
  width: 1px;
  height: 4rem;
  z-index: 1;
  display: inline-block;
}
.search-searchbar-text {
  order: 1;
  color: #405a6e;
  font-weight: 700;
  font-size: 2rem;
  transition: all 0.5s ease;
}
.search-searchbar-inputs {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.search-searchbar-inputs > * :not(.input-separator) {
  margin-left: 6%;
}
.search-searchbar-inputs__right, .search-searchbar-inputs__left {
  display: flex;
  width: 100%;
}
.search-searchbar-inputs__right button, .search-searchbar-inputs__left button {
  order: 3;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  scale: 2;
  margin-right: 1rem;
}
.search-searchbar-input {
  display: inline-block;
  order: 2;
  border: none;
  height: 100%;
  width: 100%;
  font-family: inherit;
  font-size: 2rem;
}
.search-searchbar-input:focus {
  outline: none;
  color: #00233d;
}
.search-searchbar-input::placeholder {
  color: #405a6e;
}
.search-searchbar-input:focus ~ .search-searchbar-text {
  color: #00233d;
}
.search-searchbar-input:focus ~ .search-searchbar__icon {
  color: #00233d;
}
@media only screen and (width <= 37.5em) {
  .search-searchbar {
    line-height: 2.2rem;
    height: fit-content;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    outline: none;
    background-color: transparent;
  }
  .search-searchbar__icon {
    color: #405a6e;
    font-size: 1.7rem;
    display: flex !important;
    margin-right: 0.8rem;
  }
  .search-searchbar-focus {
    border-radius: 1.2rem;
  }
  .search-searchbar-separator {
    display: none;
  }
  .search-searchbar-text {
    display: none;
  }
  .search-searchbar-button {
    padding: 0.8rem;
    margin-left: 0;
    padding-right: 10px;
    align-items: center;
    background-color: #f1f8ff;
    border: none;
    cursor: pointer;
  }
  .search-searchbar-inputs {
    align-content: center;
    align-self: center;
    flex-grow: 1;
    align-items: center;
    height: 100%;
    max-width: 75em;
    min-width: 17em;
    width: 100%;
    flex-wrap: wrap;
  }
  .search-searchbar-inputs > * :not(.input-separator) {
    margin-left: 0;
  }
  .search-searchbar-inputs__left, .search-searchbar-inputs__right {
    align-self: center;
    padding-left: 2rem;
    padding-right: 0;
    height: 4.8rem;
    outline: solid 1px #e0e2eb;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    transition: outline 0.1s;
    overflow: hidden;
    background-color: #fff;
  }
  .search-searchbar-inputs__left {
    margin-bottom: 0.8rem;
  }
  .search-searchbar-inputs__left:focus-within {
    outline: 2px solid #00233d;
    outline-offset: -2px;
  }
  .search-searchbar-inputs__right {
    transform: translateY(1rem);
    display: none;
  }
  .search-searchbar-inputs__visible {
    transform: translateY(0);
    display: flex;
  }
  .search-searchbar-inputs__right:focus-within {
    outline: 2px solid #00233d;
    outline-offset: -2px;
  }
  .search-searchbar-input {
    font-size: 1.6rem;
  }
  .search-searchbar-input:focus ~ .search-searchbar-text {
    color: #00233d;
  }
}

.landing-searchbar {
  height: 7rem;
  width: 100%;
  border-radius: 0.8rem;
  background-color: #fff;
  line-height: 2.7rem;
  outline: solid 2px #e0e2eb;
  position: relative;
  display: flex;
}
.landing-searchbar-focus {
  border-radius: 1.2rem 1.2rem 1.2rem 0;
}
.landing-searchbar__icon {
  display: none !important;
  transition: all 0.5s ease;
}
.landing-searchbar__close:hover {
  opacity: 0.7;
}
.landing-searchbar-button {
  padding: 0.8rem;
}
.landing-searchbar-separator {
  background-color: #dfe1e6;
  width: 1px;
  height: 4rem;
  z-index: 1;
  display: inline-block;
}
.landing-searchbar-text {
  order: 1;
  color: #405a6e;
  font-weight: 700;
  font-size: 2rem;
  transition: all 0.5s ease;
}
.landing-searchbar-inputs {
  flex: 1;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.landing-searchbar-inputs > * :not(.input-separator) {
  margin-left: 6%;
}
.landing-searchbar-inputs__right, .landing-searchbar-inputs__left {
  display: flex;
  width: 100%;
}
.landing-searchbar-inputs__right button, .landing-searchbar-inputs__left button {
  order: 3;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  scale: 2;
  margin-right: 1rem;
}
.landing-searchbar-input {
  display: inline-block;
  order: 2;
  border: none;
  height: 100%;
  width: 100%;
  font-family: inherit;
  font-size: 2rem;
}
.landing-searchbar-input:focus {
  outline: none;
  color: #00233d;
}
.landing-searchbar-input::placeholder {
  color: #405a6e;
}
.landing-searchbar-input:focus ~ .landing-searchbar-text {
  color: #00233d;
}
.landing-searchbar-input:focus ~ .landing-searchbar__icon {
  color: #00233d;
}
.landing-searchbar {
  line-height: 2.2rem;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  outline: none;
  background-color: transparent;
}
.landing-searchbar__icon {
  color: #405a6e;
  font-size: 1.7rem;
  display: flex !important;
  margin-right: 0.8rem;
}
.landing-searchbar-focus {
  border-radius: 1.2rem;
}
.landing-searchbar-separator {
  display: none;
}
.landing-searchbar-text {
  display: none;
}
.landing-searchbar-button {
  padding: 0.8rem;
  margin-left: 0;
  padding-right: 10px;
  display: flex;
  align-items: center;
  background-color: #f1f8ff;
  border: none;
  cursor: pointer;
}
.landing-searchbar-inputs {
  align-content: center;
  align-self: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 75em;
  min-width: 17em;
  width: 100%;
  flex-wrap: wrap;
}
.landing-searchbar-inputs > * :not(.input-separator) {
  margin-left: 0;
}
.landing-searchbar-inputs__left, .landing-searchbar-inputs__right {
  align-self: center;
  padding-left: 2rem;
  padding-right: 0;
  height: 4.8rem;
  outline: solid 1px #e0e2eb;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  transition: outline 0.1s;
  overflow: hidden;
  background-color: #fff;
}
.landing-searchbar-inputs__left {
  margin-bottom: 0.8rem;
}
.landing-searchbar-inputs__left:focus-within {
  outline: 2px solid #00233d;
  outline-offset: -2px;
}
.landing-searchbar-inputs__right {
  transform: translateY(1rem);
  display: none;
}
.landing-searchbar-inputs__visible {
  transform: translateY(0);
  display: flex;
}
.landing-searchbar-inputs__right:focus-within {
  outline: 2px solid #00233d;
  outline-offset: -2px;
}
.landing-searchbar-input {
  font-size: 1.6rem;
}
.landing-searchbar-input:focus ~ .landing-searchbar-text {
  color: #00233d;
}
@media only screen and (max-width: 600px) {
  .landing .search-searchbar {
    display: none;
  }
}

.search-suggestions {
  position: absolute;
  width: 40%;
  left: 46%;
  z-index: 10;
}
@media only screen and (max-width: 1024px) {
  .search-suggestions {
    left: 40%;
  }
}
.search-results {
  margin-left: -2px;
  background-color: #fff;
  height: max-content;
  width: 100%;
  border: solid #e0e2eb 2px;
  border-top: none;
  border-radius: 0 0 1.2rem 1.2rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.search-results::after {
  content: "";
  z-index: -1;
  box-shadow: 0 0 4rem -1rem rgba(0, 0, 0, 0.15);
}
.search-results__currentlocation {
  border-radius: 0;
}
.search-item {
  padding: 1.8rem 0;
  font-weight: 400;
  color: #00233d;
  font-size: 1.8rem;
}
.search-item__icon {
  font-size: 2.2rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.search-item:hover {
  background-color: rgba(224, 226, 235, 0.3);
}
.search-item > p {
  margin-left: 6%;
}
.search-item__location {
  color: #0052cc;
  padding-left: 6%;
  display: flex;
  align-items: center;
}
.search-item__location p {
  margin-left: 0.8rem;
  width: 100%;
}

@media only screen and (width <= 37.5em) {
  .search-suggestions {
    padding-right: 0;
    margin-top: 1.7rem;
    top: 55%;
    left: 0%;
    width: 100%;
  }
  .search-results {
    margin-left: 0;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: solid #e0e2eb 1px;
  }
  .search-results::after {
    content: "";
    z-index: -1;
    box-shadow: 0 0 4rem -1rem rgba(0, 0, 0, 0.15);
  }
  .search-results__location {
    margin-left: 0;
  }
  .search-item {
    padding: 0;
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
  .search-item__icon {
    font-size: 2rem;
    margin: 1.5rem 0 1.1rem;
  }
  .search-item > p {
    margin: 1.5rem 0 1.1rem;
    margin-left: 1.2rem;
  }
  .search-item:not(:last-child) {
    border-bottom: 1px solid #dfe1e6;
  }
  .search-item__location {
    padding-left: 1.2rem;
  }
  .search-item__location p {
    margin-left: 0.8rem;
    width: 100%;
  }
}
.card {
  display: flex;
  outline: 2px solid #caccd3;
  outline-offset: -1px;
  border-radius: 8px;
  padding: 2.8rem 3.2rem;
  margin: 3rem 6rem;
  cursor: pointer;
  /* THIS JUST TURNS THE BUTTON YELLOW*/
  /* THIS JUST TURNS THE BUTTON YELLOW*/
}
@media only screen and (max-width: 767px) {
  .card {
    margin: 1.5rem;
  }
  .card__header {
    padding-bottom: 9px;
  }
  .card .card__services--wrapper {
    padding-bottom: 2px;
  }
}
.card .regText {
  color: var(--FRM-Neutral-neutral-700, #4B4C51);
  font-family: "Atkinson Hyperlegible";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px; /* Note: Letter spacing specified as 0px to match your specification */
  margin-bottom: 30px;
}
.card__active {
  outline: 2px solid #06f;
  outline-offset: -1px;
  box-shadow: 0 4px 2.2rem 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.card__left {
  display: flex;
  flex-flow: column nowrap;
  margin-right: auto;
  max-width: 100%;
}
.card__left > *:not(:last-child) {
  margin-bottom: 1.7rem;
}
.card__header {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.7rem;
  display: flex;
  align-items: center;
}
.card__pill-mobile {
  display: none;
}
.card__phone-link {
  color: inherit;
  text-decoration: none;
}
.card__phone-link:hover, .card__phone-link:active, .card__phone-link:focus {
  color: inherit;
  text-decoration: underline;
}
.card__address {
  max-width: 100%;
  width: max-content;
}
.card__address a {
  text-decoration: underline;
  color: #06f;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.4rem;
}
.card__distance {
  display: flex;
  margin-top: -10px;
}
.card__distance .distance-text {
  color: #D3961E;
  margin-left: 0.5rem;
}
.card__icon {
  margin-right: 0.5rem;
  color: #00233d;
}
.card__website .card__phone {
  display: flex;
  align-items: center;
}
.card__website {
  color: #06f;
  font-size: 1.6rem;
  text-decoration: underline;
  font-weight: 600;
}
.card__phone {
  font-size: 1.6rem;
  font-weight: 600;
}
.card__services {
  font-size: 1.4rem;
  line-height: 1.9rem;
  font-weight: 700;
}
.card .card__services--wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px; /* Adjust the gap between items */
  align-items: center; /* Adjust the vertical alignment */
  margin-bottom: 10px; /* Adjust the space between the tags and the services */
}
.card .card__buttons {
  display: flex;
  gap: 10px; /* Adjusts space between buttons */
  margin-top: 10px; /* Ensures there’s space between the tags and buttons */
  justify-content: flex-start; /* Aligns buttons to the left */
}
.card .button__yellow {
  background-color: #FFD700; /* Gold/yellow color */
  color: #fff; /* White text */
  border-color: #FFD700; /* Matching border color */
}
.card .button__yellow:hover,
.card .button__yellow:focus {
  background-color: #FFC107; /* Slightly darker yellow on hover */
  border-color: #FFC107;
}
.card__pills {
  margin-bottom: -10px;
}
.card__pills-layout {
  display: flex;
  flex-wrap: wrap;
}
.card__pills-layout > *:not(:last-child) {
  margin-right: 1.2rem;
}
.card__pills-layout > * {
  margin-bottom: 10px;
}
@media only screen and (width <= 37.5rem) {
  .card {
    padding: 1.4rem 1.6rem;
  }
  .card__left {
    width: 100%;
  }
  .card__left > *:not(:last-child) {
    margin-bottom: 0.8rem;
  }
  .card__right {
    display: none;
  }
  .card__address {
    text-decoration: none;
    color: #707070;
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
  .card__distance {
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding-bottom: 9px;
  }
  .card__website, .card__phone {
    display: none;
  }
  .card__services {
    font-size: 1.4rem;
    font-weight: 700;
  }
  .card__hours-pill {
    display: inline-block;
    position: relative;
    top: 0;
    right: 0;
    padding-bottom: 0.8rem;
  }
}

.table {
  border-color: #fff;
  padding: 0 3.2rem;
  outline: 2px solid #caccd3;
  outline-offset: -2px;
  border-radius: 0.8rem;
  font-size: 2rem;
  font-weight: 700;
  color: #00233d;
}
@media only screen and (max-width: 767px) {
  .table {
    padding: 0 1rem;
  }
}
.table__icon {
  font-size: 2.7rem;
}
.table__blue {
  color: #0052cc;
}
.table__blue span {
  cursor: pointer;
}
.table__blue a {
  text-decoration: none;
  color: #0052cc;
}
.table > * {
  padding: 3.2rem 0;
}
.table > *:not(:last-child) {
  border-bottom: 2px solid rgba(202, 204, 211, 0.9);
}
.table__section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table__report {
  display: none;
}
.table__content {
  margin-right: auto;
}
.table__address {
  margin-top: 1.2rem;
  line-height: 2.2rem;
  font-size: 1.6rem;
  font-weight: 600;
}
.table__distance {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  color: rgba(0, 35, 61, 0.5);
}
.table__hours {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
}
.table__hours span {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
}
.table__hours--table {
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-weight: 600;
  width: 100%;
}
.table__hours--table-left {
  height: 4rem;
  width: 100px;
}
.table__hours--table-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 4rem;
  font-weight: 400;
}
.table__hours .timeColor {
  color: #007d1d;
}
.table__hours .timeColor__text {
  vertical-align: top;
}
@media only screen and (width <= 37.5em) {
  .table {
    outline: none;
  }
}

.footer {
  background-color: #3A3B3F;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 10rem 5vw;
  min-height: 15vh;
  flex-shrink: 0;
  margin: auto;
}
.footer__content {
  display: flex;
  width: fit-content;
  color: #fff;
  width: 70rem;
  margin-left: 4rem;
}
.footer__column {
  flex: 1;
  width: fit-content;
  margin-right: 3rem;
}
.footer__header {
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 1.4rem;
}
.footer__link {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 3.6rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
}
.footer__link-logo {
  margin-right: 0.5rem;
  font-size: inherit;
  display: flex !important;
}
.footer__link:hover {
  text-decoration: underline;
}
.footer__guide {
  background-color: none;
  width: 15rem;
}
@media only screen and (max-width: 768px) {
  .footer {
    flex-direction: column;
    align-items: center;
  }
  .footer__content {
    margin: auto;
    width: fit-content;
  }
  .footer__logo {
    transform-origin: top left;
    transform: scale(0.8);
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 600px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4.8rem 0;
    height: fit-content;
  }
  .footer__logo {
    order: 1;
    transform: scale(1);
    margin: auto;
  }
  .footer__content {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: fit-content;
    color: #fff;
  }
  .footer__content > *:not(:last-child) {
    margin-right: 0;
  }
  .footer__header {
    font-weight: 700;
    font-size: 1.6rem;
    margin-bottom: 0.8rem;
  }
  .footer__column {
    margin-bottom: 4rem;
    flex: 1;
    width: fit-content;
    margin-right: 0;
  }
  .footer__link {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 3.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
  .footer__link-logo {
    margin-right: 0.5rem;
    font-size: inherit;
  }
  .footer__guide {
    display: none;
  }
}

.location-pin {
  position: relative;
  width: 4.6rem;
  height: min-content;
}
.location-pin__yourIcon {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  font-size: 4.6rem;
  color: rgb(225, 6, 6);
}
.location-pin__yourIcon svg circle {
  color: #fff;
  transform-origin: initial;
  transform: scale(3) translateY(3.5rem);
}
.location-pin__icon {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  font-size: 4.6rem;
  color: #00388e;
}
.location-pin__icon--active {
  color: #0052cc;
}
.location-pin__icon svg circle {
  transform-origin: initial;
  transform: scale(3) translateY(3.5rem);
}
.location-pin__number {
  padding-top: 0.8rem;
  position: relative;
  color: #fff;
  z-index: 1;
  font-size: 1.7rem;
  text-align: center;
}
.location-pin__you {
  padding-top: 1.3rem;
  position: relative;
  color: #fff;
  z-index: 1;
  font-size: 1.4rem;
  text-align: center;
}

.header-landing {
  padding: 6rem 5rem;
  width: 100%;
  background-color: #f1f8ff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #0d2b58;
  font-family: Atkinson Hyperlegible;
  flex: auto;
  box-sizing: border-box;
  position: relative;
}
.header-landing__desktop {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}
.header-landing__container {
  display: flex;
}
.header-landing__chapter {
  flex: 7;
  padding: 10px;
  box-sizing: border-box;
}
.header-landing__illustration {
  flex: 2;
  padding: 10px;
  box-sizing: border-box;
  max-height: 10px;
  position: relative;
}
.header-landing__btnwrapper {
  align-self: right;
  display: flex;
  gap: 2rem;
  font-size: 2rem;
  align-items: center;
}
.header-landing__btnwrapper span {
  cursor: pointer;
}
.header-landing__signout {
  cursor: pointer;
  width: max-content;
  text-decoration: none;
  color: #0d2b58;
}
.header-landing__head {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
.header-landing__language {
  visibility: hidden;
}
.header-landing__description {
  padding-left: 1rem;
  font-size: 2rem;
}
@media only screen and (max-width: 767px) {
  .header-landing__description {
    padding: 0 4%;
  }
}
.header-landing__search-wrapper {
  width: 75%;
  margin: 5rem 8rem;
}
.header-landing__mobile {
  display: none;
}
.header-landing__browse {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}
.header-landing__browse-text {
  cursor: pointer;
}
.header-landing .header-partner-logos {
  margin: 2rem;
  text-align: center; /* Center aligns the content */
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center;
  max-width: 80%;
}
.header-landing .header-partner-logos img {
  max-width: 100%; /* Make the image as large as the container allows */
  width: 600px; /* Set a specific width for larger displays */
  height: auto;
  display: block;
  margin: 0 auto; /* Center the image */
}
.header-landing .header-partner-sponsors {
  text-align: center;
  font-size: 1.2rem; /* Adjust as needed */
  margin-bottom: 1rem; /* Space between text and logos */
}
@media only screen and (max-width: 767px) {
  .header-landing {
    padding: 1.6rem 0.9rem;
  }
  .header-landing__description {
    padding-top: 2rem;
    padding-left: 7%;
  }
  .header-landing__search-wrapper {
    display: none;
  }
  .header-landing__mobile {
    margin-top: 1.3rem;
    display: block;
  }
  .header-landing__illustration {
    display: none;
  }
  .header-landing .header-partner-logos {
    margin-top: 2rem;
    text-align: center; /* Center aligns the content */
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center;
    max-width: 80%;
    margin-right: 20px;
  }
  .header-landing .header-partner-logos img {
    max-width: 200%; /* Make the image as large as the container allows */
    width: 800px;
    margin: 0 auto;
    display: inline-block;
  }
}

.header-search {
  width: 100%;
  padding: 2.4rem 5rem;
  background-color: #f1f8ff;
  color: #fff;
}
.header-search__language {
  visibility: hidden;
}
.header-search__desktop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 2rem;
}
.header-search__left-content {
  display: flex;
  align-items: right;
  flex-grow: 1;
}
.header-search__btnwrapper {
  display: flex;
  gap: 2rem;
  align-items: center;
}
.header-search__btnwrapper span {
  cursor: pointer;
}
.header-search__search-wrapper {
  width: 70%;
  margin: 0 2rem;
}
.header-search__mobile {
  display: none;
  padding: 0 4%;
}
.header-search__signout {
  cursor: pointer;
  width: max-content;
  text-decoration: none;
  color: #0d2b58;
}
@media only screen and (max-width: 767px) {
  .header-search {
    padding: 1.6rem 0.9rem;
  }
  .header-search__search-wrapper {
    display: none;
  }
  .header-search__mobile {
    margin-top: 1.3rem;
    display: block;
    min-width: 100%;
    padding: 0 4%;
  }
  .header-search .header-partner-logos img {
    display: none;
  }
}

.header-dashboard {
  width: 100%;
  padding: 2.4rem 5rem;
  background-color: #f1f8ff;
  color: #0d2b58;
}
.header-dashboard__language {
  visibility: hidden;
}
.header-dashboard__desktop {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-dashboard__signout {
  cursor: pointer;
  width: max-content;
  text-decoration: none;
  color: #0d2b58;
}
.header-dashboard__btnwrapper {
  align-self: right;
  display: flex;
  gap: 2rem;
  align-items: center;
}
.header-dashboard__btnwrapper span {
  cursor: pointer;
}
@media only screen and (max-width: 37.5em) {
  .header-dashboard {
    padding: 1.6rem 0.9rem;
  }
}

/* Welcome Text Styling */
.header-landing__welcome-mobile {
  color: var(--FRM-Primary-primary-900, #0D2B58); /* Primary text color */
  font-family: "Atkinson Hyperlegible", sans-serif; /* Font family */
  font-size: 20px; /* Match the size from Figma */
  font-weight: 700; /* Bold text for emphasis */
  line-height: 28px; /* Adjust line height for readability */
  text-align: center; /* Center-align the text */
  margin-top: 1rem;
  margin-bottom: 1rem; /* Add spacing below the text */
  padding: 0 16px; /* Add side padding for mobile */
}

/* Description Text Styling */
.header-landing__description-mobile {
  color: var(--FRM-Primary-primary-900, #0D2B58); /* Primary text color */
  font-family: "Atkinson Hyperlegible", sans-serif; /* Font family */
  font-size: 14px; /* Match the size from Figma */
  font-weight: 400; /* Regular font weight */
  line-height: 22px; /* Adjust line height for readability */
  text-align: center; /* Center-align the text */
  margin: 0 auto 1.5rem; /* Center and add spacing below */
  padding: 0 16px; /* Add side padding for mobile */
  max-width: 280px; /* Optional: Limit width for better readability */
}

.checkbox {
  outline: solid 3.5px #00233d;
  outline-offset: -3.5px;
  border-radius: 3px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox__checked {
  background-color: #0052cc;
  outline-color: #0052cc;
}
.checkbox__icon {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox__icon svg polyline {
  stroke-width: 9rem !important;
}

.map-zoom {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 2px solid #caccd3;
  border-radius: 0.8rem;
}
.map-zoom__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.1rem;
  min-height: 4.6rem;
}
.map-zoom__divider {
  height: 2px;
  background-color: #caccd3;
}

.toggle {
  display: flex;
  align-items: stretch;
  border: 2px solid #caccd3;
  border-radius: 0.8rem;
  width: fit-content;
  font-size: 1.6rem;
  font-weight: 700;
  overflow: hidden;
}
.toggle-button {
  padding: 1.3rem 1.4rem;
}
.toggle-button--selected {
  background-color: #0052cc;
  color: #fff;
}
.toggle-separator {
  width: 2px;
  background-color: #caccd3;
}

.filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 1.8rem;
  color: #00233d;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.08px;
  text-align: center;
  outline: 2px solid #caccd3;
  outline-offset: -1px;
  border-radius: 3.3rem;
  padding: 1.7rem 2.2rem;
}
@media only screen and (width <= 37.5em) {
  .filter-button {
    border-radius: 0.8rem;
    padding: 1.3rem 1.3rem;
    font-size: 1.6rem;
  }
}
.filter-button--selected {
  background-color: #e3eeff;
  outline: 2px solid #0052cc;
}
.filter-button__filtered {
  background-color: #0052cc;
  height: 1.6rem;
  width: 1.6rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  padding: 1.2rem;
}
.filter-button__filtered span {
  font-family: "SF Pro Display", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.graphic-button {
  border: 2px solid #caccd3;
  border-radius: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  padding: 1.8rem 1.6rem;
  cursor: pointer;
}
@media only screen and (width <= 37.5em) {
  .graphic-button {
    padding: 1.6rem;
  }
}
.graphic-button__graphic {
  height: 5.4rem;
  width: auto;
}
.graphic-button__body {
  display: flex;
  flex-direction: column;
  width: 20rem;
  margin-left: 2.7rem;
  color: #00233d;
}
@media only screen and (width <= 37.5em) {
  .graphic-button__body {
    width: 100%;
  }
}
.graphic-button__title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media only screen and (width <= 37.5em) {
  .graphic-button__title {
    font-size: 1.6rem;
    line-height: 2.2rem;
  }
}
.graphic-button__paragraph {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.photo-gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.66vw 1.53vw;
}
.photo-gallery__photo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  height: 0;
  padding-bottom: 58.6206896552%;
  outline: #caccd3 2px solid;
  border-radius: 1.2rem;
  transition: all 0.2s ease-in-out;
}
.photo-gallery__photo-wrapper:hover {
  box-shadow: 0 4px 1.5rem rgba(0, 0, 0, 0.26);
}
@media only screen and (width <= 37.5em) {
  .photo-gallery__photo-wrapper {
    outline: #caccd3 1px solid;
  }
}
.photo-gallery__photo {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media only screen and (width <= 37.5em) {
  .photo-gallery {
    grid-gap: 1.3rem 1.1rem;
  }
}

.landing {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  height: 100%;
  width: 100%;
}
.landing .heading-primary {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 600px) {
  .landing .heading-primary {
    font-size: 2.5rem;
    display: flex;
    align-self: flex-start;
    padding: 0 4%;
    margin-bottom: 0em;
  }
}
.landing__categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 1rem 5%;
}
@media only screen and (max-width: 600px) {
  .landing__categories {
    width: 90%;
    margin: 0;
    margin-top: 0rem;
    margin-bottom: 1rem;
    display: block;
    padding: 0 4%;
  }
  .landing__categories .graphic-button {
    margin-top: 1rem;
  }
}
.landing__categories > * {
  flex-basis: calc((100% - 45rem) / 4);
  max-width: 100%;
}
.landing__accordion-container {
  background-color: #f6f6fa;
  width: 95%;
  padding-top: 64px;
  padding-bottom: 64px;
}
.landing__about-heading {
  text-align: center;
}

.search-page {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
}
.search-page__main {
  display: flex;
  width: 100%;
  height: 100%;
}
.search-page__results {
  display: flex;
  height: auto;
  flex-direction: column;
  width: 65%;
}
.search-page__map {
  display: flex;
  position: sticky;
  top: 0;
  height: 100vh;
  width: 35%;
}
.search-page__filters {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
}
.search-page__filters button {
  margin: 1rem;
}
.search-page__filters .filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  font-size: 1.8rem;
  color: #00233d;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: -0.08px;
  text-align: center;
  outline: 2px solid #00233d;
  box-shadow: #00233d 0 0 0 1px inset;
  outline-offset: -1px;
  border-radius: 12px;
  padding: 0rem 2rem;
  height: 5.2rem;
  margin: 1rem;
  cursor: pointer;
}
.search-page__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: justify;
  margin: 0 20%;
}
.search-page__warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
}

.p1search {
  font-size: larger;
  font-weight: bolder;
}

/* Existing styles */
.resource-page {
  display: flex;
  flex-flow: column nowrap;
}

.resource-page__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.resource-page__content {
  display: flex;
  flex-flow: row nowrap;
  margin: 4rem;
}

.resource-page__left {
  flex-flow: column nowrap;
  flex: 2;
  margin: 2rem;
  margin-left: 20rem;
}

.resource-page__info {
  margin-top: 4rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
}

.resource-page__table {
  flex: 1;
}

.resource-page__heading {
  display: flex;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.resource-page__heading-secondary {
  font-size: 2.3rem;
  font-weight: 700;
}

.resource-page__description {
  font-size: 1.7rem;
  line-height: 1.6;
}

.resource-page__services--wrapper,
.resource-page__highlights--wrapper,
.resource-page__accessibility--wrapper {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
}

.resource-page__pills {
  display: flex;
  flex-flow: row wrap;
  gap: 4rem;
}

/* Print-specific styles */
@media print {
  .resource-page__content {
    background-color: rgb(133, 225, 255);
    display: block;
    margin: 0;
    padding: 1rem;
  }
  .resource-page__left, .resource-page__right {
    width: 100%;
    margin: 0;
  }
  .resource-page__heading {
    font-size: 4rem;
  }
  .resource-page__heading-secondary {
    font-size: 3rem;
  }
  .resource-page__description,
  .resource-page__services--wrapper,
  .resource-page__highlights--wrapper,
  .resource-page__accessibility--wrapper {
    font-size: 2rem;
  }
  .resource-page__pills {
    gap: 1rem;
  }
  .resource-page__loading,
  .button,
  .header-search, .footer,
  .return-button5, .print-button {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {
  .resource-page__content {
    flex-flow: column nowrap;
    margin: 1rem;
  }
  .resource-page__left {
    flex-flow: column nowrap;
    flex: 2;
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .resource-page__right {
    flex-flow: column nowrap;
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
.search-page__pagination {
  margin: 4rem 6rem;
}

.MuiPagination-root ul li {
  margin: 0.6rem;
  font-size: 2rem;
}

.MuiPagination-root ul li button {
  font-size: 1.8rem;
}

.search-page__buttons-wrapper--secondary {
  display: flex;
  align-items: center;
  margin: 1rem;
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1000;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: #00233d 0 0 0 2px inset;
}
.search-page__buttons-wrapper--secondary button {
  box-shadow: none;
  padding: 2.5rem 1.5rem;
}
@media only screen and (max-width: 600px) {
  .search-page__buttons-wrapper--secondary button {
    width: 100%;
    justify-content: center;
    text-align: center;
    font-size: 1.6rem;
    margin: 0;
  }
}
.search-page__buttons-wrapper--secondary:hover {
  opacity: 0.7;
}
.search-page__icon {
  color: #405a6e;
  font-size: 1.7rem;
  display: flex !important;
  margin-left: 0.8rem;
}
.search-page a {
  color: #0e0e0e;
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 700;
  cursor: pointer;
}

.leaflet-pane {
  margin-top: 3.3rem;
}

.desktop-popup .leaflet-popup-content {
  width: 235px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 12px;
  margin-bottom: 16px;
}

.desktop-popup .leaflet-popup-tip-container,
.desktop-popup .leaflet-popup-close-button {
  visibility: hidden;
}

.desktop-popup .leaflet-popup-content-wrapper {
  border-radius: 10px;
  cursor: default;
  transition-duration: 0ms;
}

.leaflet-tooltip {
  border-radius: 10px;
  color: #0052cc;
  padding: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
}

.FilterForm {
  width: 425px;
  padding: 60px 50px;
}
@media only screen and (max-width: 600px) {
  .FilterForm {
    padding: 0;
    padding-bottom: 10%;
    width: 100%;
  }
}
@media (min-width: 1440px) and (min-height: 1505px) {
  .FilterForm {
    width: 440px;
    padding: 40px 50px;
  }
}
.FilterForm__flexWrap {
  display: flex;
  justify-content: flex-start;
  margin-top: 6rem;
}
.FilterForm__flexWrap Button {
  max-width: max-content;
  margin: 2%;
  padding: 1.6rem 1.8rem;
  font-size: 18px;
  font-weight: 600;
}
@media (min-width: 1440px) and (min-height: 1505px) {
  .FilterForm__flexWrap {
    margin-top: 6rem;
  }
}
@media only screen and (max-width: 600px) {
  .FilterForm__flexWrap {
    background: #edeff6;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
  }
  .FilterForm__flexWrap Button {
    padding: 2rem 1.8rem;
  }
}
.FilterForm .filterResource {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  padding-bottom: 20px;
  color: #00233d;
}
.FilterForm .clearFilters {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #0052cc;
  cursor: pointer;
}
.FilterForm .andTags {
  margin-top: 2rem;
}
.FilterForm .andTags input {
  margin-top: 2rem;
  margin-right: 12px;
  margin-left: 5px;
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .FilterForm .andTags input {
    margin-left: 4rem;
  }
}
.FilterForm .andTags span {
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.FilterPanel__FormSection {
  column-count: 2;
  overflow-wrap: break-word;
}
@media only screen and (max-width: 600px) {
  .FilterPanel__FormSection {
    column-count: 2;
  }
}
@media (min-width: 1440px) and (min-height: 1505px) {
  .FilterPanel__FormSection {
    padding: 16px 0px;
  }
}
.FilterPanel__FormSection h1 {
  column-span: all;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #00233d;
  margin-bottom: 10px;
  margin-top: 20px;
}
@media (min-width: 1440px) and (min-height: 1505px) {
  .FilterPanel__FormSection h1 {
    margin-top: 25px;
    margin-bottom: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .FilterPanel__FormSection h1 {
    padding: 0 5rem;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
}
@media only screen and (max-width: 600px) {
  .FilterPanel__FormSection p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    padding: 0 5rem;
    color: #000000;
  }
}
.FilterPanel__FormItem {
  padding: 6px 4px;
  align-items: center;
  font-family: Nunito;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}
.FilterPanel__FormItem label {
  display: flex;
  align-items: baseline;
  width: 200px;
  padding: 18px 0px 0px 0px;
}
@media only screen and (max-width: 600px) {
  .FilterPanel__FormItem label {
    width: 100%;
    padding: 0;
  }
}
@media (min-width: 1440px) and (min-height: 1505px) {
  .FilterPanel__FormItem {
    line-height: 4rem;
  }
}
.FilterPanel__FormItem input {
  margin-right: 12px;
  cursor: pointer;
  transform: scale(1.75);
}
.FilterPanel__FormItem span {
  font-size: 15px;
  flex: 0.9;
  flex-wrap: wrap;
  line-height: 22px;
  cursor: pointer;
}
.FilterPanel__FilterDistance {
  display: grid;
  row-gap: 20px;
  row-gap: 33px;
  overflow-wrap: break-word;
}
.FilterPanel__FilterDistance input {
  margin-right: 12px;
  cursor: pointer;
}
.FilterPanel__FilterDistance span {
  font-size: 14px;
  flex: 0.9;
  flex-wrap: wrap;
  line-height: 22px;
  cursor: pointer;
}
.FilterPanel__FilterDistance h1 {
  column-span: all;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #00233d;
  margin-top: 30px;
}
@media (min-width: 1440px) and (min-height: 1505px) {
  .FilterPanel__FilterDistance {
    row-gap: 32px;
    margin-top: 28px;
  }
}
.FilterPanel__FilterVerifier {
  display: grid;
  row-gap: 20px;
  row-gap: 33px;
  overflow-wrap: break-word;
}
.FilterPanel__FilterVerifier input {
  margin-right: 12px;
  cursor: pointer;
}
.FilterPanel__FilterVerifier span {
  font-size: 14px;
  flex: 0.9;
  flex-wrap: wrap;
  line-height: 22px;
  cursor: pointer;
}
.FilterPanel__FilterVerifier h1 {
  column-span: all;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #00233d;
  margin-top: 30px;
}
@media (min-width: 1440px) and (min-height: 1505px) {
  .FilterPanel__FilterVerifier {
    row-gap: 32px;
    margin-top: 28px;
  }
}

.search-page--responsive__map {
  height: 80vh;
}
.search-page--responsive__results {
  min-height: 80vh;
  height: auto;
}
.search-page--responsive__bar {
  display: flex;
  justify-content: space-between;
  margin: 0.7rem 2rem;
}
.search-page--responsive__bar button {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #00233d;
  width: 8rem;
  margin: 0;
  background: #ffffff;
  border: 2px solid #caccd3;
}
.search-page--responsive__warning {
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
}
.search-page--responsive__loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;
  text-align: justify;
  margin: 0 10%;
}

.heading-primary {
  margin: 1rem 1rem;
}
@media only screen and (max-width: 767px) {
  .heading-primary {
    font-size: 2.5rem;
    display: flex;
    align-self: flex-start;
    padding: 0 4%;
    margin-bottom: 0em;
  }
}

.p1search {
  font-size: larger;
  font-weight: bolder;
}

.cardResponsive {
  display: flex;
  outline-offset: -1px;
  border-radius: 1.2rem;
  padding: 2.8rem 2rem;
  width: 100%;
}
.cardResponsive__desktop {
  border: 1px;
  border-radius: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  flex-flow: column nowrap;
  margin-right: auto;
}
.cardResponsive__headerDesktop {
  color: var(--FRM-Neutral-neutral-900, #2C2D30);
  font-family: "Atkinson Hyperlegible";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  width: 218px;
  cursor: pointer;
  margin-bottom: 2px;
}
.cardResponsive__verificationDesktop {
  color: var(--FRM-Primary-primary-500, #2067D2);
  font-family: "Atkinson Hyperlegible";
  font-size: 16.5px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  margin-top: 2px;
}
.cardResponsive__button-wrapperDesktop {
  display: flex;
  gap: 10px;
  align-items: center;
}
.cardResponsive__button-wrapperDesktop button {
  width: 36px;
  height: 16px;
  justify-content: center;
  border-radius: 2px;
  border: 0.8px solid var(--FRM-Neutral-neutral-700, #4B4C51);
  background: rgba(217, 217, 217, 0);
}
.cardResponsive__button-wrapperDesktop p {
  margin: 0%;
  margin-right: 10px;
  margin-bottom: 0px;
  color: var(--FRM-Neutral-neutral-700, #4B4C51);
  font-family: "Atkinson Hyperlegible";
  font-size: 14.5px;
  font-style: normal;
  font-weight: 400;
}
.cardResponsive__buttonDesktop {
  margin-left: 0px;
  width: 52px;
  height: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 2px;
  border: 1.5px solid var(--FRM-Neutral-neutral-700, #4B4C51);
  background: rgba(217, 217, 217, 0);
  cursor: pointer;
}

.request-popup .leaflet-popup-content {
  margin: 0;
}

.paragraphs-container {
  display: flex;
  flex-wrap: wrap;
  width: 160px;
  margin-right: 14px;
  margin-left: 2px;
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 4rem;
  padding: 3rem;
  border: 2px solid #caccd3;
  border-radius: 12px;
}
.dashboard__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.dashboard__addresource {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.dashboard__addresource > *:not(:first-child) {
  gap: 4rem;
}
@media only screen and (width <= 37.5em) {
  .dashboard__addresource > *:not(:first-child) {
    gap: 0.5rem;
  }
}
.dashboard__addname, .dashboard__addcontact, .dashboard__addaddress, .dashboard__addabout {
  display: flex;
}
@media only screen and (width <= 37.5em) {
  .dashboard__addname, .dashboard__addcontact, .dashboard__addaddress, .dashboard__addabout {
    flex-direction: column;
    margin-top: 2rem;
  }
}
.dashboard__addabout button {
  width: 100%;
  margin-top: 1.45rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.8em;
  letter-spacing: 0.00938em;
  box-shadow: #00233d 0 0 0 1.5px inset;
}
@media only screen and (width <= 37.5em) {
  .dashboard__addabout button {
    justify-content: flex-start;
    padding: 1.5rem;
  }
}
.dashboard__button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.dashboard__button-wrapper button {
  margin-left: 3rem;
}

.dashboardtext1 {
  margin: 1rem;
}

.dasboardtext2 {
  width: 7rem;
  margin-right: 2rem;
}

.heading-secondary {
  margin-bottom: 3rem;
}

.div1 {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.div1 button {
  margin-left: 2rem;
  width: fit-content;
  margin-top: 0;
}

.div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 5rem;
  width: 100%;
}
.div2 img {
  transform-origin: center;
  transform: scale(0.4);
}
.div2 p {
  width: max-content;
  text-align: center;
}
@media only screen and (600px <= width <= 768px) {
  .div2 p {
    scale: 0.8;
  }
}

.hours-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}
.hours-modal__card {
  width: 80%;
  max-width: 60rem;
  height: auto;
  padding: 4rem 2rem;
  border-radius: 1.2rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
}
@media screen and (max-width: 600px) {
  .hours-modal__card {
    overflow-y: scroll;
    height: 80%;
  }
}
.hours-modal__mon, .hours-modal__tue, .hours-modal__wed, .hours-modal__thu, .hours-modal__fri, .hours-modal__sat, .hours-modal__sun {
  display: flex;
  align-items: center;
  margin: 0 0;
  border: 1px solid rgba(0, 0, 0, 0.23);
}
@media screen and (max-width: 600px) {
  .hours-modal__mon, .hours-modal__tue, .hours-modal__wed, .hours-modal__thu, .hours-modal__fri, .hours-modal__sat, .hours-modal__sun {
    flex-direction: column;
  }
}
.hours-modal__text1 {
  display: flex;
  justify-content: flex-start;
  width: 12rem;
  margin-left: 2rem;
  align-items: center;
  height: 45px;
}
.hours-modal__btnWrapper {
  width: 90%;
  margin: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.timePickers__time {
  width: 25rem;
  border-left: 1px solid rgba(0, 0, 0, 0.23);
  border-right: 1px solid rgba(0, 0, 0, 0.23);
}
@media screen and (max-width: 600px) {
  .timePickers__time {
    border-top: 1px solid rgba(0, 0, 0, 0.23);
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
    border-right: none;
    border-left: none;
  }
}

.carousel__card {
  width: 80%;
  height: 80%;
  border-radius: 1.2rem;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  overflow: hidden;
}
.carousel__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.approvedResources__name, .approvedResources__contact, .approvedResources__address, .approvedResources__about {
  color: #00233d;
  font-size: 25px;
  margin-bottom: 12px;
}
.approvedResources__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.approvedResources__button-wrapper {
  display: flex;
  justify-content: space-between;
}

.resourcestext1 {
  font-size: 15px;
}

