@import 'https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap';
@import 'https://fonts.cdnfonts.com/css/sf-pro-display:wght@500';

// all project sizing is set to 10px = 1rem to simplify responsiveness

html {
    font-family: Nunito, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: $text-dark;
}

body {
    font-size: 1.6rem;
}

.heading-primary {
    font-size: 4rem;
    font-weight: 700;
}

.heading-secondary {
    font-size: 2rem;
    font-weight: 700;
}
