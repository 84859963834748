.cardResponsive {
    display: flex;
    outline-offset: -1px;
    border-radius: 1.2rem;
    padding: 2.8rem 2rem;
    width: 100%;

    //Desktop (and current mobile) styling
    &__desktop {
        //width: 238px;
        border: 1px;
        border-radius: 20px;
        filter: drop-shadow(0px 4px 4px rgba(0,0,0,0.25));
        display: flex;
        flex-flow: column nowrap;
        margin-right: auto;
    }
    &__headerDesktop {
        color: var(--FRM-Neutral-neutral-900, #2C2D30);
        font-family: "Atkinson Hyperlegible";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        width: 218px;
        cursor: pointer;
        margin-bottom: 2px;
    }
    &__verificationDesktop {
        color: var(--FRM-Primary-primary-500, #2067D2);
        font-family: "Atkinson Hyperlegible";
        font-size: 16.5px;
        font-style: normal;
        font-weight: 400;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        margin-top: 2px;
    }
    &__button-wrapperDesktop {
        display: flex;
        gap: 10px;
        align-items: center;

        button {
            width: 36px;
            height: 16px;
            justify-content: center;
            border-radius: 2px;
            border: 0.8px solid var(--FRM-Neutral-neutral-700, #4B4C51);
            background: rgba(217, 217, 217, 0.00);
        }
    }
    &__button-wrapperDesktop p {
        margin: 0%;
        margin-right: 10px;
        margin-bottom: 0px;
        color: var(--FRM-Neutral-neutral-700, #4B4C51);
        font-family: "Atkinson Hyperlegible";
        font-size: 14.5px;
        font-style: normal;
        font-weight: 400;
    }
    &__buttonDesktop {
        margin-left: 0px;
        width: 52px;
        height: 32px;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 2px;
        border: 1.5px solid var(--FRM-Neutral-neutral-700, #4B4C51);
        background: rgba(217, 217, 217, 0.00);
        cursor: pointer;
    }
}
.request-popup .leaflet-popup-content {
    margin: 0;
}
.paragraphs-container {
    display: flex;
    flex-wrap: wrap;
    width: 160px;
    margin-right: 14px;
    margin-left: 2px;
}
