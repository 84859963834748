/* Existing styles */
.resource-page {
  display: flex;
  flex-flow: column nowrap;
}

.resource-page__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.resource-page__content {
  display: flex;
  flex-flow: row nowrap;
  margin: 4rem;
}



.resource-page__left {
  flex-flow: column nowrap;
  flex: 2;
  margin: 2rem;
  margin-left: 20rem;
}

.resource-page__info {
  margin-top: 4rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
}

.resource-page__table {
  flex: 1;
}

.resource-page__heading {
  display: flex;
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.resource-page__heading-secondary {
  font-size: 2.3rem;
  font-weight: 700;
}

.resource-page__description {
  font-size: 1.7rem;
  line-height: 1.6;
}

.resource-page__services--wrapper,
.resource-page__highlights--wrapper,
.resource-page__accessibility--wrapper {
  margin-top: 20px;
  display: flex;
  flex-flow: column nowrap;
  gap: 2rem;
}

.resource-page__pills {
  display: flex;
  flex-flow: row wrap;
  gap: 4rem;
}

/* Print-specific styles */
@media print {
  .resource-page__content {
    background-color: rgb(133, 225, 255);
    display: block;
    margin: 0;
    padding: 1rem;
  }

  .resource-page__left, .resource-page__right {
    width: 100%;
    margin: 0;
    // border-width: 0
  }

  // .box-to-hide {
  //   border-width: 0 !important;
  //   box-shadow: none !important;
  // }

  .resource-page__heading {
    font-size: 4rem;
  }

  .resource-page__heading-secondary {
    font-size: 3rem;
  }

  .resource-page__description,
  .resource-page__services--wrapper,
  .resource-page__highlights--wrapper,
  .resource-page__accessibility--wrapper {
    font-size: 2rem;
  }

  .resource-page__pills {
    gap: 1rem;
  }

  .resource-page__loading,
  .button,

  .header-search, .footer,
  .return-button5, .print-button {
    display: none !important;
  }

  // .resource-page__table {
  //   flex: 1;
  //   border-width: 5
  // }
}

@media only screen and (max-width: 767px) {
  .resource-page__content {
    flex-flow: column nowrap;
    margin: 1rem;
  }

  .resource-page__left {
    flex-flow: column nowrap;
    flex: 2;
    // margin: 4rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .resource-page__right {
    flex-flow: column nowrap;
    // margin: 4rem;
    margin-left: 3rem;
    margin-right: 3rem;

  }
}