.photo-gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.66vw 1.53vw;

  &__photo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: calc(187 / 319 * 100%);
    outline: $gray-3 2px solid;
    border-radius: 1.2rem;
    transition: all 0.2s ease-in-out;

    &:hover {
      box-shadow: 0 4px 1.5rem rgb(0 0 0 / 26%);
    }

    @media only screen and (width <= 37.5em) {
      outline: $gray-3 1px solid;
    }
  }

  &__photo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media only screen and (width <= 37.5em) {
    grid-gap: 1.3rem 1.1rem;
  }
}
