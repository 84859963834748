.header-landing {
  padding: 6rem 5rem;
  width: 100%;
  background-color: #f1f8ff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #0d2b58;
  font-family: Atkinson Hyperlegible;
  flex: auto;
  box-sizing: border-box;
  position: relative;

  &__desktop {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
  }

  &__container {
    display: flex;
  }

  &__chapter {
    flex: 7;
    padding: 10px;
    box-sizing: border-box;
  }

  &__illustration {
    flex: 2;
    padding: 10px;
    box-sizing: border-box;
    max-height: 10px;
    position: relative;
  }

  &__btnwrapper {
    align-self: right;
    display: flex;
    gap: 2rem;
    font-size: 2rem;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  &__signout {
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    color: #0d2b58;
  }

  &__head {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  &__language {
    visibility: hidden;
  }

  &__description {
    padding-left: 1rem;
    font-size: 2rem;

    @media only screen and (max-width: 767px) {
      padding: 0 4%;
    }
  }

  &__search-wrapper {
    width: 75%;
    margin: 5rem 8rem;
  }

  &__mobile {
    display: none;
  }

  &__browse {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }

  &__browse-text {
    cursor: pointer;

  }

  .header-partner-logos {
    margin: 2rem;
    text-align: center; /* Center aligns the content */
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center;
    max-width: 80%;
    img {
    max-width: 100%; /* Make the image as large as the container allows */
    width: 600px; /* Set a specific width for larger displays */
    height: auto;
    display: block;
    margin: 0 auto; /* Center the image */
  }
  }
  .header-partner-sponsors {
    text-align: center;
    font-size: 1.2rem; /* Adjust as needed */
    margin-bottom: 1rem; /* Space between text and logos */
  }

  @media only screen and (max-width: 767px) {
    padding: 1.6rem 0.9rem;

    &__description {
      padding-top: 2rem;
      padding-left: 7%;
    }
  
    &__search-wrapper {
      display: none;
    }
  
    &__mobile {
      margin-top: 1.3rem;
      display: block;
    }
  
    &__illustration {
      display: none;
    }


  
    
    .header-partner-logos {
      margin-top: 2rem;
      text-align: center; /* Center aligns the content */
      display: flex;
      justify-content: center; /* Centers the content horizontally */
      align-items: center;
      max-width: 80%;
      margin-right: 20px;
  
      img {
        max-width: 200%; /* Make the image as large as the container allows */
        width: 800px;
        margin: 0 auto;
        display: inline-block;
      }
    }
  }
}

.header-search {
  width: 100%;
  padding: 2.4rem 5rem;
  background-color: #f1f8ff;
  color: #fff;

  &__language {
    visibility: hidden;
  }

  &__desktop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 2rem;
  }

  &__left-content {
    display: flex;
    align-items: right;
    flex-grow: 1;
  }

  &__btnwrapper {
    display: flex;
    gap: 2rem;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  &__search-wrapper {
    width: 70%;
    margin: 0 2rem;
  }

  &__mobile {
    display: none;
    padding: 0 4%;
  }

  &__signout {
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    color: #0d2b58;
  }

  @media only screen and (max-width: 767px) {
    padding: 1.6rem 0.9rem;

    &__search-wrapper {
      display: none;
    }

    &__mobile {
      margin-top: 1.3rem;
      display: block;
      min-width: 100%;
      padding: 0 4%;
    }

    .header-partner-logos img {
      display: none;
    }
  }
}

.header-dashboard {
  width: 100%;
  padding: 2.4rem 5rem;
  background-color: #f1f8ff;
  color: #0d2b58;

  &__language {
    visibility: hidden;
  }

  &__desktop {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__signout {
    cursor: pointer;
    width: max-content;
    text-decoration: none;
    color: #0d2b58;
  }

  &__btnwrapper {
    align-self: right;
    display: flex;
    gap: 2rem;
    align-items: center;

    span {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 37.5em) {
    padding: 1.6rem 0.9rem;
  }
}

/* Welcome Text Styling */
.header-landing__welcome-mobile {
  color: var(--FRM-Primary-primary-900, #0D2B58); /* Primary text color */
  font-family: "Atkinson Hyperlegible", sans-serif; /* Font family */
  font-size: 20px; /* Match the size from Figma */
  font-weight: 700; /* Bold text for emphasis */
  line-height: 28px; /* Adjust line height for readability */
  text-align: center; /* Center-align the text */
  margin-top: 1rem;
  margin-bottom: 1rem; /* Add spacing below the text */
  padding: 0 16px; /* Add side padding for mobile */
}

/* Description Text Styling */
.header-landing__description-mobile {
  color: var(--FRM-Primary-primary-900, #0D2B58); /* Primary text color */
  font-family: "Atkinson Hyperlegible", sans-serif; /* Font family */
  font-size: 14px; /* Match the size from Figma */
  font-weight: 400; /* Regular font weight */
  line-height: 22px; /* Adjust line height for readability */
  text-align: center; /* Center-align the text */
  margin: 0 auto 1.5rem; /* Center and add spacing below */
  padding: 0 16px; /* Add side padding for mobile */
  max-width: 280px; /* Optional: Limit width for better readability */
}
