// colors
// Primary Blue Colors
$primary-blue-50: #E9F0FB;
$primary-blue-100: #BAD0F1;
$primary-blue-200: #98B9EA;
$primary-blue-300: #6A99E1;
$primary-blue-400: #4D85DB;
$primary-blue-500: #2067D2;
$primary-blue-600: #1D5EBF;
$primary-blue-700: #174995; 
$primary-blue-800: #123974; 
$primary-blue-900: #0D2B58; 
// Neutral Gray Colors
$neutral-gray-50: #F0F0F1;
$neutral-gray-100: #D1D1D3;
$neutral-gray-200: #BABBBE;
$neutral-gray-300: #9B9CA1;
$neutral-gray-400: #87898E;
$neutral-gray-500: #696B72;
$neutral-gray-600: #606168;
$neutral-gray-700: #4B4C51;
$neutral-gray-800: #3A3B3F;
$neutral-gray-900: #2C2D30;
// Error Red Colors
$error-red-50: #FCE8E8;
$error-red-100: #F5B9B9;
$error-red-200: #F09797;
$error-red-300: #E96868;
$error-red-400: #E54A4A;
$error-red-500: #DE1D1D;
$error-red-600: #CA1A1A;
$error-red-700: #9E1515;
$error-red-800: #7A1010;
$error-red-900: #5D0C0C;
// Success Green Colors
$success-green-50: #EFF7EC;
$success-green-100: #CEE5C4;
$success-green-200: #B7D8A7;
$success-green-300: #96C77F;
$success-green-400: #81BC66;
$success-green-500: #62AB40;
$success-green-600: #599C3A;
$success-green-700: #46792D;
$success-green-800: #365E23;
$success-green-900: #29481B;
// Warning Orange Colors
$warning-orange-50: #FBF5E9;
$warning-orange-100: #F1DEB9;
$warning-orange-200: #EBCF98;
$warning-orange-300: #E2B968;
$warning-orange-400: #DCAB4B;
$warning-orange-500: #D3961E;
$warning-orange-600: #C0891B;
$warning-orange-700: #966B15;
$warning-orange-800: #745311;
$warning-orange-900: #593F0D;
// Legacy Color Variables
$primary-brighter-blue: #06f;
$primary-bright-blue: #0052cc;
$primary-dark-blue: #00388e;
$primary-darkest-blue: #233b5f;
$background-pale-gray: #edeff6;
$background-orange: #fdaa29;
$text-dark: #00233d;
$text-gray-cold: #405a6e;
$text-gray-warm: #707070;
$semantics-success: #007d1d;
$semantics-success-pale: #def3e3;
$semantics-warning: #954700;
$semantics-warning-pale: #f7e1ce;
$semantics-destructive: #c80000;
$semantics-destructive-pale: #f0d8d8;
$very-dark-blue: #172b4d;
$dark-desaturated-blue: #42526e;
$gray-1: #e0e2eb;
$gray-2: #dfe1e6;
$gray-3: #caccd3;
$off-white: #e3eeff;
$white: #fff;
$black: #000;

// spacing
$border-radius: 0.80rem;
