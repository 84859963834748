.approvedResources {
    &__name,
    &__contact,
    &__address,
    &__about {
        color: #00233d;
        font-size: 25px;
        margin-bottom: 12px;
    }
    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    &__button-wrapper {
        display: flex;
        justify-content: space-between;
    }
}

.resourcestext1 {
    font-size: 15px;
}
