.button {
  font-family: Nunito, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: $border-radius;
  color: white;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  font-size: 1.8rem;
  padding: 0.75rem 2.0rem;
  gap: 0.5rem;
  outline: 0;

  &:disabled {
    cursor: not-allowed;
  }

  // Default focus (selected) behavior for all buttons
  &:focus {
    outline: 2px solid $primary-blue-500;
    outline-offset: 4px;
  }

  &__icon {
    font-size: 1.5rem;
    color: #2067D2;
  }

  &__primary {
    background-color: $primary-blue-500;
  }

  &__primary:hover {
    background-color: $primary-blue-700;
  }

  &__primary:disabled {
    background-color: $primary-blue-100;
  }

  &__secondary {
    color: $neutral-gray-900;
    background-color: $white;
    border: 1px solid $neutral-gray-100;
    
    // These are styles for the Google, Microsoft, and Email buttons in the login page.
    // They set the background color, text color, border, and padding for those buttons.
    &Google,
    &Microsoft,
    &Email {
      background-color: #fff;
      color: #00233d;
      border: 2px solid #00233d;
      box-shadow: none;
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 0;
      padding: 1.2rem 1rem;
    }
  }

  &__secondary:hover {
    background-color: $neutral-gray-50;
  }

  // Secondary buttons use a different color for focus (selected)
  &__secondary:focus {
    outline: 2px solid $neutral-gray-900;
    outline-offset: 4px;
  }

  &__secondary:disabled {
    color: $neutral-gray-100;
    background-color: $white;
  }

  &__secondaryMap {
    color: $neutral-gray-900;
    background-color: $white;
    border: 1px solid $neutral-gray-100;
    height: 30px;  
  }

  &__secondaryMap:hover {
    background-color: $neutral-gray-50;
  }

  // Secondary buttons use a different color for focus (selected)
  &__secondaryMap:focus {
    outline: 2px solid $neutral-gray-900;
    outline-offset: 4px;
  }

  &__secondaryMap:disabled {
    color: $neutral-gray-100;
    background-color: $white;
  }

  &__error {
    background-color: $error-red-500;
  }

  &__error:hover {
    background-color: $error-red-700;
  }

  &__error:disabled {
    background-color: $error-red-100;
  }

  &__tertiary {
    color: $primary-blue-500;
    background-color: transparent;
    padding: 0.25rem 0.5rem;
  }

  &__tertiary:hover {
    background-color: $primary-blue-50;
  }

  &__tertiary:disabled {
    color: $primary-blue-100;
    background-color: transparent;
  }

  &__link {
    color: $primary-bright-blue;
    background-color: transparent;
    padding: 1rem;
  }

  &__small {
    line-height: 1.5rem;
    font-size: 1rem;
  }

  &__large {
    line-height: 3rem;
    font-size: 2rem;
  }

  &__browse-all-button {
    padding: 0.5rem 6rem; 
    font-size: 1.5rem; 
    color: #0d2b58;
    background-color: transparent;
    border: 2.5px solid $primary-blue-500;
    border-radius: 8px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    outline: none;
  }
  
  &__browse-all-button:hover {
    background-color: #0d2b58;
    color: #ffffff;
  }

  // Determines mobile styling, may need further adjustments
  @media only screen and (width <=600px) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.6rem;
    padding: 1.3rem 1.0rem;
    margin: 0 0.5rem;
  }
}