.checkbox {
  outline: solid 3.5px $text-dark;
  outline-offset: -3.5px;
  border-radius: 3px;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &__checked {
    background-color: $primary-bright-blue;
    outline-color: $primary-bright-blue;
  }

  &__icon {
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg polyline {
      stroke-width: 9rem !important;
    }
  }
}
