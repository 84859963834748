.card {
  display: flex;
  outline: 2px solid $gray-3;
  outline-offset: -1px;
  border-radius: 8px;
  padding: 2.8rem 3.2rem;
  margin: 3rem 6rem;
  cursor: pointer;

  @media only screen and (max-width: 767px) {
    margin: 1.5rem;

    // To retain the same spacing on the mobile view
    &__header {
      padding-bottom: 9px;
    }

    .card__services--wrapper {
      padding-bottom: 2px;
    }
  }

  .regText {
    color: var(--FRM-Neutral-neutral-700, #4B4C51);
    font-family: 'Atkinson Hyperlegible';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px; /* Note: Letter spacing specified as 0px to match your specification */
    margin-bottom: 30px;
  }

  &__active {
    outline: 2px solid $primary-brighter-blue;
    outline-offset: -1px;
    box-shadow: 0 4px 2.2rem 0 rgb(0 0 0 / 12%);
    cursor: pointer;
  }

  &__left {
    display: flex;
    flex-flow: column nowrap;
    margin-right: auto;
    max-width: 100%;

    &>*:not(:last-child) {
      margin-bottom: 1.7rem;
    }
  }

  &__header {
    font-size: 2rem;
    font-weight: 700;
    line-height: 2.7rem;
    display: flex;
    align-items: center;
  }

  &__pill-mobile {
    display: none;
  }

  &__phone-link {
    color: inherit;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__address {
    max-width: 100%;
    width: max-content;

    a {
      text-decoration: underline;
      color: $primary-brighter-blue;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.4rem;
    }
  }

  &__distance {
    display: flex;
    margin-top: -10px;

    .distance-text
    {
      color: $warning-orange-500;
      margin-left: 0.5rem;
    }
  }

  &__icon {
    margin-right: 0.5rem;
    color: $text-dark;
  }

  &__website &__phone {
    display: flex;
    align-items: center;
  }

  &__website {
    color: $primary-brighter-blue;
    font-size: 1.6rem;
    text-decoration: underline;
    font-weight: 600;
  }

  &__phone {
    font-size: 1.6rem;
    font-weight: 600;
  }

  &__services {
    font-size: 1.4rem;
    line-height: 1.9rem;
    font-weight: 700;
    
  }

  
  .card__services--wrapper {
    display: flex;
    flex-direction: row;
    gap: 10px; /* Adjust the gap between items */
    // justify-content: space-between; /* Adjust the horizontal alignment */
    align-items: center; /* Adjust the vertical alignment */
    margin-bottom: 10px; /* Adjust the space between the tags and the services */
  }
  
  .card__buttons {
    display: flex;
    gap: 10px; /* Adjusts space between buttons */
    margin-top: 10px; /* Ensures there’s space between the tags and buttons */
    justify-content: flex-start; /* Aligns buttons to the left */
  }
  

  /* THIS JUST TURNS THE BUTTON YELLOW*/
  .button__yellow {
    background-color: #FFD700; /* Gold/yellow color */
    color: #fff; /* White text */
    border-color: #FFD700; /* Matching border color */
  }
  /* THIS JUST TURNS THE BUTTON YELLOW*/
  .button__yellow:hover,
  /* THIS JUST TURNS THE BUTTON YELLOW*/
  .button__yellow:focus {
    background-color: #FFC107; /* Slightly darker yellow on hover */
    border-color: #FFC107;
  }
  

  &__pills {
    &-layout {
      display: flex;
      flex-wrap: wrap;
      // margin-top: 1rem;

      &>*:not(:last-child) {
        margin-right: 1.2rem;
      }

      & > * {
        margin-bottom: 10px;
      }
    }
    
    // Remove the margin from the last pill row
    margin-bottom: -10px;
  }

  // &__hours-pill {
  //   top: 2.8rem;
  //   right: 3.2rem;
  // }
  

  @media only screen and (width <=37.5rem) {
    padding: 1.4rem 1.6rem;

    &__left {
      width: 100%;

      &>*:not(:last-child) {
        margin-bottom: 0.8rem;
      }
    }

    &__right {
      display: none;
    }

    &__address {
      text-decoration: none;
      color: $text-gray-warm;
      font-size: 1.4rem;
      line-height: 1.9rem;
    }

    &__distance {
      font-size: 1.2rem;
      line-height: 1.6rem;
      padding-bottom: 9px;
    }

    &__website,
    &__phone {
      display: none;
    }

    &__services {
      font-size: 1.4rem;
      font-weight: 700;
    }

    // &__pills {
    //     overflow-x: auto;

    //     &-layout {
    //         display: inline-grid;
    //         max-width: 100%;
    //         grid-template-columns: auto auto auto;
    //         grid-auto-flow: column;
    //         grid-template-rows: auto auto;
    //         grid-gap: 1.2rem;

    //         & > *:not(:last-child) {
    //             margin-right: 0;
    //         }
    //     }
    // }

    &__hours-pill {
      display: inline-block;
      position: relative;
      top: 0;
      right: 0;
      padding-bottom: 0.8rem;
    }

   

  }
}