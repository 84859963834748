.landing {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    height: 100%;
    width: 100%;

    .heading-primary {
        margin-top: 1rem;
        margin-bottom: 1rem;
        @media only screen and (max-width: 600px) {
            font-size: 2.5rem;
            display: flex;
            align-self: flex-start;
            padding: 0 4%;
            margin-bottom: 0em;
        }
    }

    &__categories {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
        margin: 1rem 5%;
        @media only screen and (max-width: 600px) {
            width: 90%;
            margin: 0;
            margin-top: 0rem;
            margin-bottom: 1rem;
            display: block;
            padding: 0 4%;
            .graphic-button {
                margin-top: 1rem;
            }
        }

        > * {
            flex-basis: calc(
                (100% - 45rem) / 4
            ); // Set the width of each item based on the available space

            max-width: 100%;
        }
    }

    &__accordion-container {
        background-color: #f6f6fa;
        width: 95%;
        padding-top: 64px;
        padding-bottom: 64px;
    }

    &__about-heading {
        text-align: center;
    }
}
